import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';
import Button from '@truescope-web/react/lib/components/form/Button';
import ChipTextField from '@truescope-web/react/lib/components/form/ChipTextField';
import Select from '@truescope-web/react/lib/components/form/Select';
import ValidationPlaceholder from '@truescope-web/react/lib/components/form/ValidationPlaceholder';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline, { horizontalAlignment } from '@truescope-web/react/lib/components/layout/Inline';
import Notification from '@truescope-web/react/lib/components/layout/Notification';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import SkeletonWrapper from '@truescope-web/react/lib/components/loading/SkeletonWrapper';
import { validationResults, validationScore } from '@truescope-web/react/lib/utils/validation';
import { validateEmails } from '@truescope-web/react/lib/utils/validationFunctions';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import { useApiLookup } from '../../../../../components/ApiLookupProvider';
import { useConfig } from '../../../../../components/ConfigProvider';
import { sendInvites } from './api';

const InviteUsersSheet = ({ workspace_id: wid, onInviteSuccess, maxSeats, usedSeats, onClose, hasScopes }) => {
	const [getDatahubApi] = useApiLookup();
	const { config } = useConfig();
	const { match } = useRouteMatch();
	const workspace_id = !stringIsNullOrEmpty(wid) ? wid : match.params.workspace_id;
	const [emails, setEmails] = useState([]);
	const [roleId, setRoleId] = useState(config.roleOptions?.[0]?.value || '');
	const [errorMessage, setErrorMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [emailValidation, setEmailValidation] = useState(validationResults.ok());

	const handleCancel = () => {
		onClose();
	};

	const handleRoleChange = (_event, _value, rawValue) => {
		setRoleId(rawValue.value);
	};

	const handleSend = () => {
		setIsLoading(true);
		sendInvites(getDatahubApi, workspace_id, emails, roleId)
			.then(({ invites }) => {
				if (onInviteSuccess) {
					onInviteSuccess(invites);
				}
				handleCancel();
			})
			.catch((e) => {
				setErrorMessage(e);
			})
			.finally(() => setIsLoading(false));
	};

	const handleEmailsChange = (_e, emails) => {
		if (arrayIsNullOrEmpty(emails)) {
			setEmailValidation(validationResults.ok());
			setEmails([]);
			return;
		}

		const potentialSeats = usedSeats + emails.length;
		if (potentialSeats > maxSeats) {
			setErrorMessage(
				<Notification variant="warning">
					You&apos;re inviting more users than there are seats available in workspace. Seats will be occupied on a first come,
					first served basis.
				</Notification>
			);
			return;
		}

		const emailValidCheck = validateEmails(emails, true);
		setEmailValidation(emailValidCheck);

		if (emailValidCheck.score === validationScore.ok) {
			setEmails(emails);
		}
	};

	const renderMessage = () => {
		if (isNullOrUndefined(errorMessage)) {
			return null;
		}

		return <Grid item>{errorMessage}</Grid>;
	};

	if (isNullOrUndefined(roleId)) {
		return (
			<div className="full-width">
				<SkeletonWrapper />
			</div>
		);
	}

	if (stringIsNullOrEmpty(workspace_id)) {
		return (
			<Grid container>
				<Grid item>Workspace id has not been specified</Grid>
			</Grid>
		);
	}

	return (
		<Grid container padding={2}>
			<Grid item>
				<Inline horizontalAlignment={horizontalAlignment.rightAlignSiblings}>
					<Typography variant="h4">Invite users</Typography>
				</Inline>
			</Grid>
			{!hasScopes && (
				<Grid item>
					<MuiAlert severity="warning">
						This workspace has no Feeds attached to it and <b>won't</b> be accessible in the app. Please ensure you've attached
						at least one Feed <b>before</b> providing users with access to this workspace.
					</MuiAlert>
				</Grid>
			)}
			<Grid item>
				<p className="MuiFormHelperText-root">Enter a comma separated list of users that will be invited into the workspace</p>
				<ChipTextField placeholder="john.doe@test.com, jane.smith@email.com..." value={emails} onChange={handleEmailsChange} />
				<ValidationPlaceholder validationResult={emailValidation} />
			</Grid>
			<Grid item>
				<Select
					label="Role"
					value={roleId}
					options={config.roleOptions || []}
					onChange={handleRoleChange}
					placeholder="Select a role"
					fullWidth={false}
				/>
				<p className="MuiFormHelperText-root">Select a default role for these users.</p>
			</Grid>
			{renderMessage()}
			<Grid item>
				<Inline horizontalAlignment={horizontalAlignment.right}>
					<Button onClick={handleCancel} variant="secondary">
						Cancel
					</Button>
					<Button onClick={handleSend} variant="primary" loading={isLoading} disabled={arrayIsNullOrEmpty(emails)}>
						Send
					</Button>
				</Inline>
			</Grid>
		</Grid>
	);
};

export default InviteUsersSheet;
